import axios from 'axios'
const sendErrorToSlack = async (error, context = "API") => {
    if (!error) return;
    if (process.env.NEXT_PUBLIC_ENVIRONMENT_MODE && process.env.NEXT_PUBLIC_ENVIRONMENT_MODE !== 'production') { return }
    const timestamp = new Date().toISOString();
    const route = window?.location?.href || "N/A";
    let requestId = "N/A", method = "N/A", url = "N/A", requestBody = "{}", responseBody = "{}", statusCode = "N/A", responseTime = "N/A";

    if (error.response) {
        const { config, response } = error;
        requestId = response.headers["x-request-id"] || "N/A";
        method = config.method.toUpperCase();
        url = config.url;
        requestBody = config.data ? JSON.stringify(JSON.parse(config.data), null, 2) : "{}";
        responseBody = response.data ? JSON.stringify(response.data, null, 2) : "{}";
        statusCode = `${response.status} ${response.statusText}`;
        responseTime = response.headers["x-response-time"] || "N/A";
    } else if (error instanceof Error) {
        requestBody = JSON.stringify({ message: error.message, stack: error.stack }, null, 2);
    } else {
        responseBody = JSON.stringify(error, null, 2);
    }

    const slackMessage = {
        blocks: [
            { type: "header", text: { type: "plain_text", text: `🔔 ${context} Error Log` } },
            { type: "section", fields: [{ type: "mrkdwn", text: `*Timestamp:*\n${timestamp}` }, { type: "mrkdwn", text: `*Request ID:*\n${requestId}` }, { type: "mrkdwn", text: `*UI Route:*\n${route}` }] },
            { type: "section", fields: [{ type: "mrkdwn", text: `*Method:*\n${method}` }, { type: "mrkdwn", text: `*URL:*\n${url}` }] },
            { type: "section", text: { type: "mrkdwn", text: `*Request Body:*\n\`\`\`${requestBody}\`\`\`` } },
            { type: "section", fields: [{ type: "mrkdwn", text: `*Status Code:*\n${statusCode}` }, { type: "mrkdwn", text: `*Response Time:*\n${responseTime}` }] },
            { type: "section", text: { type: "mrkdwn", text: `*Response Body:*\n\`\`\`${responseBody}\`\`\`` } }
        ]
    };

    try {
        await axios.post("/api/log-to-slack", slackMessage, {
            headers: { "Content-Type": "application/json" },
        });
    } catch (slackError) {
        console.error("Failed to send error log to Slack:", slackError);
    }
};


export default sendErrorToSlack